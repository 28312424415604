(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

require("./vendors/h5bp");

/* 
 * - Mapa mediante la API de Google Maps
 *
 */

function googleMaps() {

  // Ubicación LAT
  var lugar = new google.maps.LatLng(-32.181800, -60.199897);

  // Estilos
  var estilos = [{ "featureType": "landscape", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#aa191f" }] }, { "featureType": "water", "stylers": [{ "color": "#aa191f" }] }];

  // Opciones del mapa
  var mapOptions = {
    center: lugar,
    zoom: 17,
    styles: estilos,
    scrollwheel: false
  };

  // Crear mapa
  var map = new google.maps.Map(document.getElementById("contact-map"), mapOptions);

  // Marcador
  var marker = new google.maps.Marker({
    position: lugar,
    // icon: "../assets/media/location-icon.png",
    map: map, //lo asocio al mapa
    optimized: false
  });

  var infoWindow = new google.maps.InfoWindow({
    content: "<p style='text-align:center; color:#000;'> D. French 377 - Gral. Ramírez (3164) Entre Ríos - Argentina</p>"
  });

  // google.maps.event.addListener(marker, 'click', function () {
  infoWindow.open(map, marker);
  // });
}

/* 
 * Envío del formulario de contacto
 *
 * - Ejecucion en: $(document).ready()
 */

function contactFormGo() {

  $("#contact-submit").on('click', function (e) {
    //formulario de contacto
    e.preventDefault();

    var okflag = true;
    $('#contact input, #contact textarea').filter('[required]:visible').each(function (i, requiredField) {
      if ($(requiredField).val() == '') {
        if (okflag == true) $('#contact-msg-all').fadeIn().delay(5000).fadeOut();
        okflag = false;
        $(requiredField).addClass("contact-error");
      } else $(requiredField).removeClass("contact-error");
    });

    if (okflag == true) $('#contact-msg').addClass("backGload").fadeIn().load('contacto.php', {
      nombre: $('#contact-name').val(),
      telefono: $('#contact-phone').val(),
      email: $('#contact-email').val(),
      mensaje: $('#contact-message').val()
    }, function () {
      $('#contact input, #contact textarea').val("");
      $('#contact-msg').removeClass("backGload");
    });
  });

  $("#online-price-submit").on('click', function (e) {
    //formulario de contacto
    e.preventDefault();

    var okflag = true;
    $('#online-price input, #online-price textarea').filter('[required]:visible').each(function (i, requiredField) {
      if ($(requiredField).val() == '') {
        if (okflag == true) $('#online-price-msg-all').fadeIn().delay(5000).fadeOut();
        okflag = false;
        $(requiredField).addClass("contact-error");
      } else $(requiredField).removeClass("contact-error");
    });

    if (okflag == true) $('#online-price-msg').addClass("backGload").fadeIn().load('cotizacion.php', {
      desde: $('#online-price-from').val(),
      hacia: $('#online-price-toward').val(),
      nombre: $('#online-price-name').val(),
      telefono: $('#online-price-phone').val(),
      email: $('#online-price-email').val(),
      mensaje: $('#online-price-message').val()
    }, function () {
      $('#online-price input, #online-price textarea').val("");
      $('#online-price-msg').removeClass("backGload");
    });
  });
}

/* 
 * Ejecuto funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
function goMaterialize() {
  $(".button-collapse").sideNav();
  $('.goto').scrollSpy();
}

/* 
 * Ejecuto funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
function goSliders() {

  $("#home").slick({
    arrows: true,
    dots: false,
    autoplay: true
  });

  // $(".buisness-carousel").slick({
  //   arrows: false,
  //   dots: true,
  //   fade: true,
  //   autoplay: true
  // });
}

/*
 * 
 * Ejecuto el llamado de todas las funciones Nac y Pop
 *
 */
$(document).ready(function () {

  goSliders();
  goMaterialize();
  contactFormGo();
  priceMap();
  googleMaps();
});

},{"./vendors/h5bp":2}],2:[function(require,module,exports){
'use strict';

// Avoid `console` errors in browsers that lack a console.
(function () {
  'use strict';

  var method;
  var noop = function noop() {};
  var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'];
  var length = methods.length;
  var console = window.console = window.console || {};

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
})();

},{}]},{},[1])

